@import url("https://fonts.googleapis.com/css2?family=Cormorant+Infant:ital@0;1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");

$red: #a1151a;
$grey: #848484;
$mustard: #d7b344;

.sg-splash {
  background-color: $red;
  padding: 100px;
  font-family: "Nunito", sans-serif;
  position: relative;

  @media (max-width: 922px) {
    padding: 50px;
  }
}

.sg-splash__inner-container {
  display: grid;
  grid-template-columns: repeat(29, minmax(0, 1fr));
  width: 100%;
  max-width: 1240px;
  margin: auto;
}

.sg-splash__inner-left {
  grid-column: 1 / 12;
  text-align: left;

  @media (max-width: 600px) {
    grid-column: 1 / 30;
  }
}

.sg-splash__inner-left h1 {
  color: white;
  font-family: "Cormorant Infant", serif;
  font-size: 80px;

  @media (max-width: 1200px) {
    font-size: 60px;
  }

  @media (max-width: 922px) {
    font-size: 40px;
  }
}

.sg-splash__inner-left h1 .italic {
  color: white;
  font-style: italic;
}

.sg-splash__inner-left h2 {
  color: white;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.33;

  @media (max-width: 922px) {
    font-size: 14px;
  }
}

.sg-splash__inner-left .portfolio-button {
  background: $mustard;
  border: none;
  border-radius: 4px;
  font-size: 18.75px;
  font-weight: 700;
  padding: 20px 60px;
  margin-top: 30px;

  @media (max-width: 922px) {
    font-size: 14px;
    padding: 15px 45px;
  }
}

.sg-splash__inner-right {
  grid-column: 14 / 30;

  @media (max-width: 600px) {
    display: none;
  }
}

.sg-splash__inner-right .graphics-container--outer {
  display: flex;
  align-items: center;
  height: 100%;
}

.sg-splash__inner-right .graphics-container--inner {
  width: 100%;
  height: 50%;
  position: relative;
  margin-left: -15%;
  margin-top: -15%;
}

.sg-splash__inner-right img {
  border-radius: 12px;
}

.sg-splash__inner-right .splash-image-1 {
  position: absolute;
  width: 70%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.2);
}

.sg-splash__inner-right .splash-image-2 {
  position: absolute;
  width: 40%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  margin-left: 43.5%;
  margin-top: 23.5%;
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.2);
}

.sg-splash__inner-right .mustard-rectangle {
  position: absolute;
  width: 48%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  margin-left: 21.5%;
  margin-top: 15.5%;
}

.stamp {
  position: absolute;
  height: 281px;
  width: 252px;
  bottom: -149px;
  right: 0;
}
